<template>
  <div class="view-home">
    <div class="jumbotron">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-center text-lg-left">
            <div class="jumbotron-title">
              {{ $t('main-page.title') }}
            </div>
            <b-button @click="$bvModal.show('register')" class="jumbotron-btn invisible">
              {{ $t('main-page.registration') }}
            </b-button>
            <div class="jumbotron-subtitle">
              <span>{{ $t('main-page.active-users') }}</span>
              <h1>150 943</h1>
            </div>
          </div>

          <div class=" col-lg-5 ">
            <div class="card auth-form">
              <div class="form-header">
                <div class="form-title">{{ $t('signin.title') }}</div>
                <div class="form-subtitle">
                  {{ $t('signin.title-2') }}
                </div>
              </div>
              <div class="form-body">
                <div class="form-group">
                  <input type="text"
                         class="form-control"
                         :placeholder="$t('signin.phone-placeholder')"
                         v-model="phone">
                </div>
                <!-- <div class="form-group">
                    <label for="">{{ $t('signin.select-user-label') }}</label>
                    <select type="text"
                        class="select form-control"
                        :placeholder="$t('signin.first-name-placeholder')">
                        <option value="" selected>Кенжебаева Сауле (020101******)</option>
                    </select>
                </div> -->
                <div class="form-group">
                  <label for="">{{ $t('signin.password-label') }}</label>
                  <input type="password"
                         class="form-control"
                         :placeholder="$t('signin.password-placeholder')"
                         v-model="password">
                </div>

              </div>
              <div class="form-footer pt-0 d-flex flex-column">
                <a href="#" class="order-1 order-lg-1">{{ $t('signin.forgot-password') }}</a>
                <div class="form-footer-title order-3 order-lg-2">
                  {{ $t('signin.call-center') }}
                  <br>8 800 080 48 48
                </div>
                <button type="button"
                        class="btn btn-primary btn-block order-2 order-lg-3"
                        @click="loginRedirect">{{ $t('signin.button') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <section class="coursers">
      <div class="section-title">
        {{ $t('main-page.our-courses-title') }}
      </div>
      <div class="container">
        <div class="row form-row">
          <div class="col-lg-3 col-8">
            <div class="form-group">
              <label for="area">{{ $t('main-page.region-label') }}</label>
              <input type="text" class="form-control" id="area" :placeholder="$t('main-page.region-placeholder')">
            </div>
          </div>
          <div class="col-lg-4 col-10">
            <div class="form-group">
              <label for="subject">{{ $t('main-page.lesson-label') }}</label>
              <input type="text" class="form-control" id="subject"
                     :placeholder="$t('main-page.lesson-placeholder')">
            </div>
          </div>
          <div class="col-lg-3 col-8">
            <div class="form-group">
              <label for="teacher">{{ $t('main-page.teacher-label') }}</label>
              <input type="text" class="form-control" id="teacher" :placeholder="$t('main-page.teacher-placeholder')">
            </div>
          </div>
          <div class="col-lg-2 d-flex align-items-end">
            <div class="form-group w-100 mt-4 mt-lg-0 h-100 d-flex align-items-end">
              <div class="btn btn-primary ">
                {{ $t('main-page.search-button') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row sort-row">
          <div class="col-4 ml-auto">
            <div class="form-group row align-items-center justify-content-end pr-4">
              <div class="text-right pr-0">
                <label for="sort" class="select-label">{{ $t('main-page.sort-by') }}</label>
              </div>
              <div class=" pl-1">
                <select class="form-control pl-2" id="sort">
                  <option value=""> {{ $t('main-page.popular') }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-100 px-2">
            <div v-for="teacher in teachers" :key="teacher.id">
              <div class="card">
                <div class="card-image ">
                  <img :src="'/images/' + $t(teacher.img)" alt="" class="w-100 ">
                </div>
                <div class="card-content">
                  <div class="card-content-title">
                    {{ $t(teacher.name) }}
                  </div>
                  <div class="card-content-subtitle">
                    {{ $t(teacher.course) }}
                  </div>
                  <ul class="list-group">
                    <li class="list-group-item">
                                            <span class="icon">
                                                <svg width="10" height="9" viewBox="0 0 10 9" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5 3.39844C5.93845 3.39844 6.69922 2.63767 6.69922 1.69922C6.69922 0.760766 5.93845 0 5 0C4.06155 0 3.30078 0.760766 3.30078 1.69922C3.30078 2.63767 4.06155 3.39844 5 3.39844Z"
                                                        fill="#666666" />
                                                    <path
                                                        d="M8.4375 3.39845C9.03077 3.39845 9.51172 2.9175 9.51172 2.32422C9.51172 1.73095 9.03077 1.25 8.4375 1.25C7.84423 1.25 7.36328 1.73095 7.36328 2.32422C7.36328 2.9175 7.84423 3.39845 8.4375 3.39845Z"
                                                        fill="#666666" />
                                                    <path
                                                        d="M1.5625 3.39845C2.15577 3.39845 2.63672 2.9175 2.63672 2.32422C2.63672 1.73095 2.15577 1.25 1.5625 1.25C0.969225 1.25 0.488281 1.73095 0.488281 2.32422C0.488281 2.9175 0.969225 3.39845 1.5625 3.39845Z"
                                                        fill="#666666" />
                                                    <path
                                                        d="M2.6209 4.36314C2.19805 4.0167 1.8151 4.06256 1.32617 4.06256C0.594922 4.06256 0 4.65396 0 5.38072V7.51372C0 7.82934 0.257617 8.08598 0.574414 8.08598C1.94211 8.08598 1.77734 8.11073 1.77734 8.027C1.77734 6.51556 1.59832 5.40714 2.6209 4.36314Z"
                                                        fill="#666666" />
                                                    <path
                                                        d="M5.46485 4.06992C4.61087 3.99869 3.86858 4.07074 3.22833 4.59922C2.1569 5.45743 2.36309 6.61296 2.36309 8.02657C2.36309 8.40058 2.66739 8.71056 3.04708 8.71056C7.16977 8.71056 7.33386 8.84355 7.57833 8.30216C7.6585 8.11907 7.63653 8.17726 7.63653 6.42579C7.63653 5.03465 6.43198 4.06992 5.46485 4.06992Z"
                                                        fill="#666666" />
                                                    <path
                                                        d="M8.67363 4.06251C8.18203 4.06251 7.80119 4.01712 7.37891 4.36309C8.39385 5.39934 8.22246 6.43213 8.22246 8.02695C8.22246 8.1112 8.08568 8.08593 9.40488 8.08593C9.73301 8.08593 9.9998 7.82011 9.9998 7.49335V5.38067C9.9998 4.65391 9.40488 4.06251 8.67363 4.06251Z"
                                                        fill="#666666" />
                                                </svg>

                                            </span>
                      <span>
                                                {{ $t(teacher.students) }}
                                            </span>
                    </li>
                    <li class="list-group-item">
                                            <span class="icon">
                                                <svg width="8" height="10" viewBox="0 0 8 10" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5.47525 7.80283H7.66926V0H0V10H5.47525V7.80283ZM3.83463 6.95963H1.75129V6.37369H3.83463V6.95963ZM4.45963 7.7474V8.33334H3.20963V7.7474H4.45963ZM5.91797 5.70963H1.75129V5.12369H5.91797V5.70963ZM5.91797 4.45963H1.75129V3.87369H5.91797V4.45963ZM5.91797 3.00131H1.75129V2.41537H2.97906C2.93893 2.31232 2.91666 2.20041 2.91666 2.08334C2.91666 1.57717 3.32846 1.16537 3.83463 1.16537C4.3408 1.16537 4.7526 1.57717 4.7526 2.08334C4.7526 2.20041 4.73033 2.31232 4.6902 2.41537H5.91797V3.00131Z"
                                                        fill="#666666" />
                                                </svg>
                                            </span>
                      <span>
                                                {{ $t(teacher.lessons) }}
                                            </span>
                    </li>
                    <li class="list-group-item">
                                            <span class="icon">
                                                <svg width="10" height="9" viewBox="0 0 10 9" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.58994 3.23898C9.55916 3.14413 9.47728 3.07501 9.3787 3.06068L6.60933 2.65787L5.37079 0.14602C5.32672 0.0566021 5.23573 0 5.13612 0C5.03649 0 4.94552 0.0566021 4.90142 0.14602L3.66284 2.65787L0.893514 3.06068C0.794951 3.07501 0.713033 3.14413 0.682255 3.23896C0.65146 3.33381 0.677143 3.43792 0.748488 3.50751L2.75234 5.46272L2.27936 8.22354C2.26251 8.32183 2.30288 8.42113 2.38347 8.47976C2.42906 8.51291 2.48307 8.52978 2.53733 8.52978C2.57899 8.52978 2.6208 8.51984 2.65908 8.49969L5.1361 7.19618L7.61301 8.49967C7.70121 8.54607 7.80804 8.53835 7.88864 8.47974C7.96923 8.42113 8.00962 8.3218 7.99278 8.22351L7.51965 5.46272L9.52373 3.50749C9.59505 3.43792 9.62075 3.33381 9.58994 3.23898Z"
                                                        fill="#666666" />
                                                </svg>
                                            </span>
                      <span>
                                                {{ $t(teacher.rating) }}
                                            </span>
                    </li>
                  </ul>
                  <div class="card-content-text">
                    <p>{{ $t(teacher.desc) }}</p>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="card-footer-title">
                    {{ $t(teacher.feedbacks) }}
                  </div>
                  <router-link tag="a" to="/teacher" class="btn btn-danger">
                    {{ $t('main-page.more-button') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 text-lg-right text-center">
            <router-link tag="a" to="/search-results" class="btn btn-primary ">
              {{ $t('main-page.all-teachers') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="about" id="about-us">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 m-auto">
            <div class="section-title">
              {{ $t('main-page.video-title') }}
            </div>
            <div class="video" style="background-image: url('/images/video-bg.jpg');" data-fancybox
                 data-width="640" data-height="360" href="video.mp4">
              <div class="icon-play">
                <svg width="24" height="27" viewBox="0 0 24 27" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.5637 13.746L0.981902 26.7836L0.981903 0.708332L23.5637 13.746Z"
                        fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 m-auto text-center ">
            <button class="btn btn-primary py-3">
              {{ $t('main-page.to-registration') }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="sponsor">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 m-auto">
            <div class="section-title mb-lg-5 mb-4">
              {{ $t('main-page.sponsors-title') }}
            </div>
            <ul class="list-group border-0 mb-5
                            list-group-horizontal
                            align-items-center
                            flex-wrap flex-md-nowrap justify-content-center">
              <li class="list-group-item border-0" v-if="this.$i18n.locale === 'ru'">
                <img src="/images/sponsor-1.png" alt="" class="img-fluid">
              </li>
              <li class="list-group-item border-0" v-else-if="this.$i18n.locale === 'kz'">
                <img src="/images/nazarbaev-qory.png" alt="" class="img-fluid" width="91">
              </li>
              <li class="list-group-item border-0">
                <img src="/images/sponsor-2.png" alt="" class="img-fluid">
              </li>
              <li class="list-group-item border-0">
                <img src="/images/sponsor-3.png" alt="" class="img-fluid">
              </li>
              <li class="list-group-item border-0">
                <img src="/images/sponsor-4.png" alt="" class="img-fluid">
              </li>
              <li class="list-group-item border-0">
                <img src="/images/sponsor-5.png" alt="" class="img-fluid">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="reviews">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="section-title">
              {{ $t('main-page.reviews-title') }}
            </div>
          </div>
        </div>
        <carousel class="wrap-item owl-carousel owl-theme d-block"
                  data-pagination = false
                  data-autoplay = true
                  data-navigation = true
                  data-itemscustom = "[[0,1]]"
                  id = "slider"
                  :autoplay = true
                  :autoplayTimeout = 20000
                  :responsive="{0:{items:1,nav:false},900:{items:1,nav:true},1000:{items:2,nav:true}}">
          <div v-for="slider in sliders" :key="slider.id">
            <div class="card-body shadow">
              <div class="card-title">
                {{ $t(slider.title) }}
              </div>
              <div class="card-content">
                {{ $t(slider.content) }}
              </div>
              <div class="card-tooltip-arrow">
              </div>
            </div>
            <div class="card-footer">
              <div class="author-img">
                <img :src="'/images/' + $t(slider.img)" alt="">
              </div>
              <span>
                                <div class="author-name">{{ $t(slider.name) }}</div>
                                <div class="author-pos">{{ $t(slider.parent) }}</div>
                            </span>
            </div>
          </div>
        </carousel>
      </div>
    </section>

    <section class="faq">
      <div class="container">
        <div class="section-title">
          {{ $t('main-page.faq-title') }}
        </div>
        <div class="row">
          <div class="col-lg-9 m-auto">
            <div class="col-md-12 content">
              <div id="accordion">
                <div class="card" v-for="(data, key) in accordionData" :key="data.id">
                  <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                    <h5 class="mb-0">
                      <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                        {{ $t(data.title) }}
                      </button>
                    </h5>
                  </div>
                  <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
                    <div class="card-body pt-0">
                      <hr class="mt-0">
                      <div v-html="$t(data.content)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-primary">
            {{ $t('main-page.faq-more-btn') }}
          </button>
        </div>
      </div>
    </section>

    <!-- <section class="question" id="rest-questions">
        <div class="container">
            <div class="row">
                <div class="col-lg-11">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="section-title pr-3">
                                {{ $t('main-page.any-questions') }}
                            </div>
                            <div class="section-subtitle text-right pr-3">{{ $t('main-page.answer-questions') }}</div>
                            <ul class="list-group border-0 text-right">
                                <li class="list-group-item border-0  ml-lg-auto">
                                    {{ $t('main-page.leave-request') }}
                                </li>
                                <li class="list-group-item border-0  ml-lg-auto">
                                    {{ $t('main-page.manager-info-1') }}
                                </li>
                                <li class="list-group-item border-0  ml-lg-auto">
                                    {{ $t('main-page.manager-info-2') }}
                                </li>

                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <div class="card shadow">
                                <div class="card-header">
                                    <div class="section-title">
                                        {{ $t('main-page.request-form-title') }}
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <textarea class="form-control " rows="4"
                                            :placeholder="$t('main-page.your-question-placeholder')"></textarea>
                                    </div>

                                    <div class="form-group">
                                        <input type="text" class="form-control"
                                            :placeholder="$t('main-page.your-name-placeholder')">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control"
                                            :placeholder="$t('main-page.your-phone-placeholder')">
                                    </div>
                                    <div class="form-group">
                                        <button type="text" class="btn btn-danger btn-block">
                                            {{ $t('main-page.send-button') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="project">
      <div class="container">
        <div class="section-title">
          {{ $t('main-page.projects-title') }}
        </div>

        <div class="row">
          <div class="col-lg-10 m-auto">

            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 offset-3 offset-sm-0 col-6 ">
                <div class="card">
                  <div class="card-body">
                    <div class="card-image">
                      <img src="/images/p-1.svg" alt="">
                    </div>
                  </div>
                  <div class="card-title">
                    {{ $t('main-page.project-qabilet') }}
                  </div>
                  <a href="https://qabilet.elbasyacademy.kz" target="_blank" class="card-link">
                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M14.7957 5.00688L10.332 0.543126C10.0596 0.270781 9.61794 0.270781 9.34559 0.543126C9.07319 0.815526 9.07319 1.2571 9.34559 1.5295L12.6187 4.80261H0.697462C0.312295 4.80261 0 5.1149 0 5.50007C0 5.88518 0.312295 6.19753 0.697462 6.19753H12.6187L9.34571 9.47063C9.07331 9.74303 9.07331 10.1846 9.34571 10.457C9.48185 10.5931 9.6604 10.6613 9.83889 10.6613C10.0174 10.6613 10.1959 10.5931 10.3321 10.457L14.7957 5.99326C15.0681 5.72086 15.0681 5.27928 14.7957 5.00688Z"
                          fill="white"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 offset-3 offset-sm-0 col-6">
                <div class="card">
                  <div class="card-body">
                    <div class="card-image">
                      <img height="42" src="/images/p-3.svg" alt="">
                    </div>
                  </div>
                  <div class="card-title">
                    {{ $t('main-page.project-myn-bala') }}
                  </div>
                  <a href="https://1000bala.elbasyacademy.kz" target="_blank" class="card-link">
                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M14.7957 5.00688L10.332 0.543126C10.0596 0.270781 9.61794 0.270781 9.34559 0.543126C9.07319 0.815526 9.07319 1.2571 9.34559 1.5295L12.6187 4.80261H0.697462C0.312295 4.80261 0 5.1149 0 5.50007C0 5.88518 0.312295 6.19753 0.697462 6.19753H12.6187L9.34571 9.47063C9.07331 9.74303 9.07331 10.1846 9.34571 10.457C9.48185 10.5931 9.6604 10.6613 9.83889 10.6613C10.0174 10.6613 10.1959 10.5931 10.3321 10.457L14.7957 5.99326C15.0681 5.72086 15.0681 5.27928 14.7957 5.00688Z"
                          fill="white"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 offset-3 offset-sm-0 col-6">
                <div class="card">
                  <div class="card-body">
                    <div class="card-image">
                      <img src="/images/p-4.svg" alt="">
                    </div>
                  </div>
                  <div class="card-title">
                    {{ $t('main-page.project-birge-oqy') }}
                  </div>
                  <a href="https://birgeoqy.kz" target="_blank" class="card-link">
                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M14.7957 5.00688L10.332 0.543126C10.0596 0.270781 9.61794 0.270781 9.34559 0.543126C9.07319 0.815526 9.07319 1.2571 9.34559 1.5295L12.6187 4.80261H0.697462C0.312295 4.80261 0 5.1149 0 5.50007C0 5.88518 0.312295 6.19753 0.697462 6.19753H12.6187L9.34571 9.47063C9.07331 9.74303 9.07331 10.1846 9.34571 10.457C9.48185 10.5931 9.6604 10.6613 9.83889 10.6613C10.0174 10.6613 10.1959 10.5931 10.3321 10.457L14.7957 5.99326C15.0681 5.72086 15.0681 5.27928 14.7957 5.00688Z"
                          fill="white"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 offset-3 offset-sm-0 col-6">
                <div class="card">
                  <div class="card-body">
                    <div class="card-image">
                      <img src="/images/p-2.svg" alt="">
                    </div>
                  </div>
                  <div class="card-title">
                    {{ $t('main-page.project-medal-elbasy') }}
                  </div>
                  <a href="https://medal.elbasyacademy.kz" target="_blank" class="card-link">
                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M14.7957 5.00688L10.332 0.543126C10.0596 0.270781 9.61794 0.270781 9.34559 0.543126C9.07319 0.815526 9.07319 1.2571 9.34559 1.5295L12.6187 4.80261H0.697462C0.312295 4.80261 0 5.1149 0 5.50007C0 5.88518 0.312295 6.19753 0.697462 6.19753H12.6187L9.34571 9.47063C9.07331 9.74303 9.07331 10.1846 9.34571 10.457C9.48185 10.5931 9.6604 10.6613 9.83889 10.6613C10.0174 10.6613 10.1959 10.5931 10.3321 10.457L14.7957 5.99326C15.0681 5.72086 15.0681 5.27928 14.7957 5.00688Z"
                          fill="white"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal hide-footer hide-header header-close id="register">
      <div class="modal-dialog modal-md mt-0">
        <div class="modal-content auth-form border-0">
          <div class="form-header">
            <div class="form-title">{{ $t('signup.title') }}</div>
            <button @click="$bvModal.hide('register')" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="form-subtitle">
              {{ $t('signup.title-2') }}
            </div>
          </div>
          <div class="form-body">
            <div class="form-group">
              <input type="text" class="form-control" :placeholder="$t('signup.lastname-placeholder')">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" :placeholder="$t('signup.firstname-placeholder')">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" :placeholder="$t('signup.patronymic-placeholder')">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" :placeholder="$t('signup.phone-placeholder')">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" :placeholder="$t('signup.password-placeholder')">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" :placeholder="$t('signup.promocode-placeholder')">
            </div>
          </div>
          <div class="form-footer">
            <button type="button" class="btn btn-primary btn-block">{{ $t('signup.signup-btn') }}</button>
            <div class="form-footer-title">
              {{ $t('signup.already-have-account') }} <a href="#">{{ $t('signup.log-in-button') }}</a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import carousel from 'vue-owl-carousel'
// import {minLength, required} from "vuelidate/lib/validators";

export default {
  components: {
    Carousel,
    Slide,
    carousel
  },
  data(){
    return {
      phone: '',
      password: '',
      tab: 'comment3',
      accordion: 'collapse0',
      path: '',
      accordionData: [
        {
          'id': "1",
          'title': 'main-page.question-1',
          'content': 'main-page.answer-1',
        },
        {
          'id': "2",
          'title': 'main-page.question-2',
          'content': 'main-page.answer-2'
        },
        {
          'id': "3",
          'title': 'main-page.question-3',
          'content': 'main-page.answer-3'
        },
        {
          'id': "4",
          'title': 'main-page.question-4',
          'content': 'main-page.answer-4'
        },
        {
          'id': "5",
          'title': 'main-page.question-5',
          'content': 'main-page.answer-5'
        },
        {
          'id': "6",
          'title': 'main-page.question-6',
          'content': 'main-page.answer-6'
        }
      ],
      sliders: [
        {
          'id': "1",
          'title': 'main-page.review-1-title',
          'content': 'main-page.review-1-content',
          'img': 'main-page.review-1-img',
          'name': 'main-page.review-1-name',
          'parent': 'main-page.review-1-parent',
          'isActive': true
        },
        {
          'id': "2",
          'title': 'main-page.review-2-title',
          'content': 'main-page.review-2-content',
          'img': 'main-page.review-2-img',
          'name': 'main-page.review-2-name',
          'parent': 'main-page.review-2-parent',
          'isActive': true
        },
        {
          'id': "3",
          'title': 'main-page.review-3-title',
          'content': 'main-page.review-3-content',
          'img': 'main-page.review-3-img',
          'name': 'main-page.review-3-name',
          'parent': 'main-page.review-3-parent',
          'isActive': true
        },
        {
          'id': "4",
          'title': 'main-page.review-4-title',
          'content': 'main-page.review-4-content',
          'img': 'main-page.review-4-img',
          'name': 'main-page.review-4-name',
          'parent': 'main-page.review-4-parent',
          'isActive': true
        },
      ],
      teachers: [
        {
          'id': "1",
          'name': 'main-page.teacher-1-name',
          'course': 'main-page.teacher-1-course',
          'students': 'main-page.teacher-1-students',
          'lessons': 'main-page.teacher-1-lessons',
          'rating': 'main-page.teacher-1-rating',
          'desc': 'main-page.teacher-1-desc',
          'feedbacks': 'main-page.teacher-1-feedbacks',
          'img': 'main-page.teacher-1-img',
        },
        {
          'id': "2",
          'name': 'main-page.teacher-2-name',
          'course': 'main-page.teacher-2-course',
          'students': 'main-page.teacher-2-students',
          'lessons': 'main-page.teacher-2-lessons',
          'rating': 'main-page.teacher-2-rating',
          'desc': 'main-page.teacher-2-desc',
          'feedbacks': 'main-page.teacher-2-feedbacks',
          'img': 'main-page.teacher-2-img',
        },
        {
          'id': "3",
          'name': 'main-page.teacher-3-name',
          'course': 'main-page.teacher-3-course',
          'students': 'main-page.teacher-3-students',
          'lessons': 'main-page.teacher-3-lessons',
          'rating': 'main-page.teacher-3-rating',
          'desc': 'main-page.teacher-3-desc',
          'feedbacks': 'main-page.teacher-3-feedbacks',
          'img': 'main-page.teacher-3-img',
        },
        {
          'id': "4",
          'name': 'main-page.teacher-4-name',
          'course': 'main-page.teacher-4-course',
          'students': 'main-page.teacher-4-students',
          'lessons': 'main-page.teacher-4-lessons',
          'rating': 'main-page.teacher-4-rating',
          'desc': 'main-page.teacher-4-desc',
          'feedbacks': 'main-page.teacher-4-feedbacks',
          'img': 'main-page.teacher-4-img',
        },
      ],
    }
  },
  // validations: {
  //     phone: {
  //         required: false,
  //         minLength: minLength(18)
  //     },
  //     password: {
  //         required,
  //         minLength: minLength(6)
  //     },
  // },
  methods: {
    loginRedirect(){
      let url = "https://onlinemektep.net/api/v2/os/login";
      let redirect = "https://onlinemektep.net/behalf_auth?hash=";
      let data = JSON.stringify({
        'login': this.phone,
        'password': this.password
      });

      $.ajax({
        url: url,
        type: "POST",
        data: data,
        headers: {
          "X-Localization": this.$i18n.locale,
          "Content-Type": "application/json"
        },
        success: function(res) {
          window.location.href = redirect + res.hash;
        },
        error: function(res) {
          alert("Ошибка: заполните данные корректно");
        }
      });
    }
  },
  mounted () {
    if(window.location.hash) {
      let one = document.getElementsByClassName('sponsor')[0].offsetHeight + document.getElementsByClassName('reviews')[0].offsetHeight + document.getElementsByClassName('faq')[0].offsetHeight + document.getElementsByClassName('project')[0].offsetHeight + document.getElementsByClassName('footer')[0].offsetHeight + 700
      window.scrollTo(0, document.getElementById('app').offsetHeight - one)
    }
  },
}



</script>
<style>
.modal-dialog .modal-content .modal-body {
  padding: 0;
}
.reviews {
  background: #fbfbfb;
  padding: 60px 0 100px 0;
}

.reviews .section-title {
  margin-bottom: 55px;
}

.reviews .card {
  border: none;
  background: none;
  margin-bottom: 30px;
}

.reviews .card-body {
  padding: 32px 32px 37px 32px;
  margin-bottom: 28px;
  position: relative;
  border-radius: 10px;
  margin: 0 20px 28px 20px;
}

.reviews .card-tooltip-arrow {
  width: 50px;
  height: 25px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow: hidden;
}

.reviews .card-tooltip-arrow::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 50%;
  -webkit-box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
}

.reviews .card-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #18191f;
}

.reviews .card-content {
  font-family: "Roboto Condensed";
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #18191f;
  min-height: 115px;
}

.reviews .card-footer {
  background: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  width: 55%;
  padding: 0;
}

.reviews .card-footer span {
  margin-left: 16px;
}

.reviews .card-footer .author-name {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #18191f;
}

.reviews .card-footer .author-pos {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 24px;
  color: #18191f;
}

.reviews .owl-dots {
  margin-top: 53px !important;
}

.reviews .owl-dots .owl-dot.active span {
  background: #3d2872;
}

.reviews .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  background: #6c64be;
}

.reviews .owl-carousel .owl-nav {
  display: none !important;
  -webkit-box-shadow: 0px 2.69104px 5.38208px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2.69104px 5.38208px rgba(0, 0, 0, 0.1);
  border-radius: 6.72761px;
  width: 60%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.reviews .owl-carousel .owl-nav button {
  width: 33%;
  background: #eaf1ff;
  padding: 10px !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.reviews .owl-carousel .owl-nav button .owl-prev {
  padding: 10px;
}

.reviews .owl-carousel .owl-nav button:hover {
  background: #473f95;
}

.reviews .owl-carousel .owl-nav button:hover svg {
  fill: white;
  stroke: white;
}

.reviews .owl-carousel .owl-nav .reviews-count {
  margin: 0;
  padding: 0;
  width: 33%;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.reviews .owl-carousel .owl-nav .reviews-count li {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding: 2px 5px;
}

.reviews .owl-carousel .owl-nav .reviews-count li:first-child {
  border-right: 1px solid #737373;
  font-weight: bold;
}
#accordion {
  padding: 60px 0;
}
#accordion .card {
  border-radius: 10px;
  box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
  margin-bottom: 5px;
  border: none;
}
#accordion .card-header {
  background: #ffffff;
  border-radius: 10px;
  border: none;
}
#accordion .card-body {
  font-weight: lighter;
}
#accordion .card .btn {
  color: #000;
  font-size: 1.1rem;
  line-height: 23px;
  font-weight: lighter;
  position: relative;
  padding-left: 24px;
}
#accordion .card .btn:hover {
  text-decoration: none;
}
#accordion .card .btn:focus,
#accordion .card .btn:active {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}
#accordion .card .btn:after {
  content: " ";
  display: block;
  position: absolute;
  width: 8px;
  height: 18px;
  background-image: url("/images/accordion-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  left: 0px;
  margin-top: -9px;
  transition: all 0.2s ease;
}
#accordion .card .btn.active:after {
  transform: rotate(90deg);
}
#accordion .card .btn[aria-expanded=true]:after {
  transform: rotate(90deg);
  transition: all 0.2s ease;
}
</style>